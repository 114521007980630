<template>
  <p :class="percentageChange > 0 ? 'negative-percentage-change' : 'positive-percentage-change'">
    ({{ formatPercentNumber(percentageChange) }})
  </p>
</template>
<script>
import { getCurrentInstance } from 'vue';
import { formatBelowOnePercent, returnDashInsteadOfNull } from '../purchaseManagementFormatters';

export default {
  props: {
    percentageChange: { type: Number, default: 0 },
  },
  setup() {
    const formatPercent = (value) => returnDashInsteadOfNull(formatBelowOnePercent(value));
    const root = getCurrentInstance().proxy;

    const formatPercentNumber = (percentageChange) => {
      const sign = percentageChange > 0 ? '+' : '';
      if (root.$direction === 'ltr') {
        return `${sign}${formatPercent(percentageChange)}`;
      } else {
        return `${formatPercent(percentageChange)}${percentageChange > 0 ? '+' : ''}`;
      }
    };
    return {
      formatPercentNumber,
    };
  },
};
</script>
<style lang="scss" scoped>
.negative-percentage-change {
  color: #e52044;
}
.positive-percentage-change {
  color: #00b37a;
}
</style>
