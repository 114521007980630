<template>
  <div class="percentage-badge badge d-flex align-items-center">
    <div class="percentage">{{ formatPercent(value) }}</div>
    <FullArrowUpIcon v-if="value > 0" width="20px" height="20px" />
    <FullArrowDownIcon v-if="value < 0" width="20px" height="20px" />
  </div>
</template>

<script>
import { percent } from '@/locale/numberConfig';
import { FullArrowUpIcon, FullArrowDownIcon } from '@/assets/icons';

export default {
  components: { FullArrowUpIcon, FullArrowDownIcon },
  props: {
    value: { type: Number, required: true },
  },
  methods: {
    formatPercent(number) {
      return typeof number === 'number' && !Number.isNaN(number)
        ? Number(Math.abs(number) / 100).toLocaleString(this.$i18n.locale, { ...percent, maximumFractionDigits: 0 })
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.percentage-badge {
  background: #f5f7fd;
  padding: 0px;

  [dir='ltr'] & {
    padding-left: 6px;
  }
  [dir='rtl'] & {
    padding-right: 6px;
  }
  .percentage {
    padding-top: 2px;
  }
}
</style>
