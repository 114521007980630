<template>
  <span>
    <span v-for="(part, index) in formattedContent" :key="index">
      <span v-if="part.type === 'bold'" style="font-weight: bold">{{ part.text }}</span>
      <br v-else-if="part.type === 'br'" />
      <span v-else>{{ part.text }}</span>
    </span>
  </span>
</template>

<script>
import { computed } from 'vue';

// this component is used to format text with bold and line breaks to not use html tags in the content to prevent from xss attacks.
export default {
  name: 'FormattedText',
  props: {
    text: { type: String, required: true },
  },

  setup(props) {
    const contentText = computed(() => props.text);

    const formattedContent = computed(() => {
      const parts = contentText.value.split(/(<\/?b>|<br>)/);

      let bold = false;
      let output = [];

      parts.forEach((part) => {
        if (part === '<b>') {
          bold = true;
        } else if (part === '</b>') {
          bold = false;
        } else if (part === '<br>') {
          output.push({ text: '\n', type: 'br' });
        } else {
          output.push({ text: part, type: bold ? 'bold' : 'normal' });
        }
      });

      return output;
    });

    return { formattedContent };
  },
};
</script>
