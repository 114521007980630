<template>
  <div v-loading="loading" class="bg-white">
    <div>
      <div class="d-flex align-items-center title">
        <h3>{{ $t('terms.supplierTerms.generalTerms.paymentTerms') }}</h3>
      </div>
      <ul class="list-group">
        <li class="list-group-item px-4 py-2">
          <div class="row d-flex align-items-center">
            <div class="col-2">
              <p class="fw-bold" :class="$direction === 'ltr' ? 'me-7' : 'ms-7'">
                {{ $t('terms.supplierTerms.generalTerms.paymentMethod') }}
              </p>
            </div>
            <div class="col d-flex justify-content-between align-items-center">
              <p v-if="paymentTerm && paymentTerm.paymentMethod === 'bankTransfer' && !paymentTerm.directDebit">
                {{ `${translate('bankTransfer')}: ${bankAccountDetails}` }}
              </p>
              <p v-if="paymentTerm && paymentTerm.paymentMethod === 'bankTransfer' && paymentTerm.directDebit">
                {{
                  `${translate('bankTransfer')} -
                 ${$t('terms.supplierTerms.generalTerms.directDebit', {
                   day: paymentTerm.directDebitDay,
                   when: translate(paymentTerm.beforeBilling ? 'before' : 'after'),
                 })}`
                }}
              </p>
              <p v-if="paymentTerm && paymentTerm.paymentMethod === 'creditCard' && !paymentTerm.directDebit">
                {{ translate('creditCard') }}
              </p>
              <p v-if="paymentTerm && paymentTerm.paymentMethod === 'creditCard' && paymentTerm.directDebit">
                {{
                  `${translate('creditCard')} -
                  ${$t('terms.supplierTerms.generalTerms.directDebit', {
                    day: paymentTerm.directDebitDay,
                    when: translate(paymentTerm.beforeBilling ? 'before' : 'after'),
                  })}`
                }}
              </p>
              <p v-if="paymentTerm && paymentTerm.paymentMethod === 'cheque'">
                {{ translate('cheque') }}
              </p>
              <span v-else />
              <Button type="text" class="text-primary p-0" @click="isPaymentMethodModalOpen = true">
                {{ translate('edit') }}
              </Button>
            </div>
          </div>
        </li>
        <li class="list-group-item px-4 py-2">
          <div class="row d-flex align-items-center">
            <div class="col-2">
              <p class="fw-bold" :class="$direction === 'ltr' ? 'me-7' : 'ms-7'">
                {{ translate('paymentDate') }}
              </p>
            </div>
            <div class="col d-flex justify-content-between align-items-center">
              <p v-if="currentPaymentDueNetIsDefined">
                {{
                  $tc(
                    `payment.paymentTable.paymentTermsPopover.paymentDueNet${currentEom ? 'Eom' : ''}`,
                    currentPaymentDueNet,
                    {
                      count: currentPaymentDueNet,
                    }
                  )
                }}
              </p>
              <span v-else />
              <Button type="text" class="text-primary p-0" @click="isPaymentDueModalOpen = true">
                {{ translate('edit') }}
              </Button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <PaymentDueModal
      v-if="isPaymentDueModalOpen"
      :payment-due-net="currentPaymentDueNet"
      :supplier="supplier.name"
      :country-code="currentTenant.countryCode"
      :eom="currentEom"
      :loading="paymentTermChangedForceLoading"
      @close="isPaymentDueModalOpen = false"
      @submit="handlePaymentDueNetChange"
    />
    <PaymentMethodModal
      v-if="isPaymentMethodModalOpen"
      :bank-accounts="bankAccounts"
      :payment-term="paymentTerm"
      @close="isPaymentMethodModalOpen = false"
      @update="handlePaymentMethodChange"
    />
  </div>
</template>

<script>
import { computed, getCurrentInstance, ref, onBeforeUnmount } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { omit, reject, isNil } from 'ramda';

import { Button } from '@/modules/core';
import { BANK_NUMBER_TO_BANK_NAME } from '@/modules/purchase-management/tools/constants';
import { useTenancy } from '@/modules/auth';
import { useBankAccounts } from '@/modules/payment';

import { useTerms, TERM_UPDATE_MUTATION_NEW, TERM_CREATE_MUTATION_NEW } from './paymentTerms/compositions/terms';
import PaymentDueModal from './paymentTerms/PaymentDueModal';
import PaymentMethodModal from './paymentTerms/PaymentMethodModal';

const timeoutIds = [];
const sleep = async (ms) => new Promise((resolve) => timeoutIds.push(setTimeout(resolve, ms)));

export default {
  components: { PaymentDueModal, PaymentMethodModal, Button },
  props: {
    supplier: { type: Object, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const {
      terms,
      refetch,
      loading: termsLoading,
    } = useTerms(
      computed(() => ({ type: 'payment', supplierId: props.supplier.id, businessId: currentTenant.value.id }))
    );

    const paymentTerm = computed(() => terms.value[0]);
    const currentPaymentDueNet = computed(() =>
      paymentTerm.value && Number.isInteger(paymentTerm.value.paymentDueNet) ? paymentTerm.value.paymentDueNet : null
    );

    const currentEom = computed(() => {
      const defaultByCountry = currentTenant.countryCode === 'IL';
      return isNil(paymentTerm.value?.eom) ? defaultByCountry : paymentTerm.value.eom;
    });

    const { bankAccounts } = useBankAccounts(computed(() => props.supplier.id));
    const supplierBankAccount = computed(() =>
      bankAccounts.value.find(({ id }) => id === paymentTerm.value?.bankAccountId)
    );

    const {
      mutate: createTerm,
      loading: createTermLoading,
      onDone: createTermOnDone,
      onError: createTermOnError,
    } = useMutation(TERM_CREATE_MUTATION_NEW);
    const {
      mutate: updateTerm,
      loading: updateTermLoading,
      onDone: updateTermOnDone,
      onError: updateTermOnError,
    } = useMutation(TERM_UPDATE_MUTATION_NEW);

    createTermOnDone(() => {
      root.$message.success(root.$t('terms.supplierTerms.generalTerms.paymentTermSuccess'));
      refetch();
    });
    updateTermOnDone(() => {
      root.$message.success(root.$t('terms.supplierTerms.generalTerms.paymentTermSuccess'));
      refetch();
    });

    createTermOnError(() => root.$message.error(root.$t('terms.supplierTerms.generalTerms.paymentTermError')));
    updateTermOnError(() => root.$message.error(root.$t('terms.supplierTerms.generalTerms.paymentTermError')));

    onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));

    return {
      paymentTerm,
      createTerm,
      updateTerm,
      loading: computed(() => termsLoading.value || createTermLoading.value || updateTermLoading.value),
      isPaymentMethodModalOpen: ref(false),
      isPaymentDueModalOpen: ref(false),
      currentPaymentDueNet,
      currentEom,
      currentTenant,
      bankAccounts,
      supplierBankAccount,
      paymentTermChangedForceLoading: ref(false),
    };
  },
  computed: {
    bankAccountDetails() {
      if (this.supplierBankAccount) {
        const { accountName, accountNumber, bankNumber, branchNumber } = this.supplierBankAccount;
        const bankName = BANK_NUMBER_TO_BANK_NAME[bankNumber];
        return `${accountName}, ${bankName} (${bankNumber}), ${this.$t(
          'supplierAccountDialog.tabs.paymentMethod.bankBranchNumber'
        )} ${branchNumber}, ${this.$t('supplierAccountDialog.tabs.paymentMethod.bankAccountNumber')} ${accountNumber}`;
      }
      return '';
    },
    currentPaymentDueNetIsDefined() {
      return Number.isInteger(this.currentPaymentDueNet);
    },
  },
  methods: {
    translate(key) {
      return this.$t(`terms.supplierTerms.generalTerms.${key}`);
    },
    async handlePaymentDueNetChange(newPaymentDueNet, eom) {
      this.paymentTermChangedForceLoading = true;
      if (!this.paymentTerm) {
        await this.createTerm({
          termCreateInput: {
            businessId: this.currentTenant.id,
            supplierId: this.supplier.id,
            fromDate: new Date().toISOString().split('T')[0],
            type: 'payment',
            paymentDueNet: newPaymentDueNet,
            eom,
          },
        });
      } else {
        await this.updateTerm({
          termId: this.paymentTerm.id,
          termUpdateInput: {
            ...omit(['id', 'active', '__typename'], this.paymentTerm),
            paymentDueNet: newPaymentDueNet,
            businessId: this.currentTenant.id,
            eom,
            fromDate: new Date(this.paymentTerm.fromDate).toISOString().split('T')[0],
          },
        });
        await sleep(8000);
        this.isPaymentDueModalOpen = false;
      }
      this.paymentTermChangedForceLoading = false;
    },
    handlePaymentMethodChange(newPaymentMethod) {
      this.isPaymentMethodModalOpen = false;
      if (!this.paymentTerm) {
        this.createTerm({
          termCreateInput: reject(isNil, {
            businessId: this.currentTenant.id,
            supplierId: this.supplier.id,
            fromDate: new Date().toISOString().split('T')[0],
            type: 'payment',
            ...newPaymentMethod,
          }),
        });
      } else {
        this.updateTerm({
          termId: this.paymentTerm.id,
          termUpdateInput: reject(isNil, {
            businessId: this.currentTenant.id,
            fromDate: new Date(this.paymentTerm.fromDate).toISOString().split('T')[0],
            ...omit(['id', 'active', '__typename', 'fromDate'], this.paymentTerm),
            ...newPaymentMethod,
          }),
        });
      }
    },
  },
};
</script>

<style scoped>
.title {
  height: 4rem;
}
</style>
