<template>
  <el-tooltip placement="top-end" effect="dark" :content="updatedTooltipText">
    <Button type="secondary" class="export-to-excel-button" :disabled="disableButton" @click="onClick">
      <ExportExcelIcon />
    </Button>
  </el-tooltip>
</template>
<script>
import { ref, getCurrentInstance } from 'vue';

import { ExportExcelIcon } from '@/assets/icons';
import { Button } from '@/modules/core/components';

export default {
  components: {
    ExportExcelIcon,
    Button,
  },
  props: {
    tooltipText: { type: String, default: '' },
    disableButton: { type: Boolean, default: false },
  },
  emits: ['on-click'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const defaultText = root.$t('terms.supplierTerms.supplierTermTable.excel.exportToExcel');
    const updatedTooltipText = ref(props.tooltipText == '' ? defaultText : props.tooltipText);
    const onClick = () => emit('on-click');

    return {
      onClick,
      updatedTooltipText,
    };
  },
};
</script>
<style lang="scss" scoped>
.export-to-excel-button {
  padding: 0.25rem;
  margin: 0;
}
</style>
